import { isEmpty, isNil, without, propEq, find, includes } from "ramda";
import { format as formatDate, addMonths } from "date-fns";
import { setCookie } from "../util/cookieProvider";

export function valueExists(value: any) {
  return !(isEmpty(value) || isNil(value));
}

export function pxToEm(px: number, fontSize: number) {
  return px / fontSize;
}

export function heightPercentsToPx(height: number) {
  const vh = window.innerHeight / 100;

  return `${pxToEm(vh * height, 16)}rem`;
}

export function capitalizeString(string: string) {
  if (typeof string !== "string") return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function removeArrayElement(element: any, array: any[]): any[] {
  return without(element, array);
}

export function getDate(date: Date) {
  let parsedDate;
  if (date instanceof Date) {
    parsedDate = date;
  } else {
    parsedDate = new Date(date);
  }

  return {
    ddmmyyyy: formatDate(parsedDate, "dd-MM-yyyy"),
    iso: formatDate(parsedDate, "yyyy-MM-dd"),
    dotSeparated: formatDate(parsedDate, "dd.MM.yyyy"),
    dotSeparatedTime: formatDate(parsedDate, "dd.MM.yyyy HH:mm")
  };
}

export function navigationConstructor(permissions: any) {
  const pagePermissions = [
    {
      id: 1,
      actionObject: "MarketplaceTicketCollection",
      actionName: "view",
      location: "/marketplace-collections",
      icon: "store"
    },
    {
      id: 2,
      actionObject: "TicketCollection",
      actionName: "view",
      location: "/collections",
      icon: "favorite"
    },
    {
      id: 3,
      actionObject: "Child",
      actionName: "view",
      location: "/children",
      icon: "person"
    },
    {
      id: 4,
      actionObject: "Parent",
      actionName: "create",
      location: "/parents",
      icon: "person"
    },
    {
      id: 5,
      actionObject: "MunicipalityAdmin",
      actionName: "create",
      location: "/municipality-admins",
      icon: "person"
    },
    {
      id: 6,
      actionObject: "ServiceProviderManager",
      actionName: "view",
      location: "/service-providers",
      icon: "person"
    },
    {
      id: 7,
      actionObject: "MunicipalityManager",
      actionName: "create",
      location: "/municipality-managers",
      icon: "person"
    },
    {
      id: 8,
      actionObject: "User",
      actionName: "notify",
      location: "/notify",
      icon: "sms"
    },
    {
      id: 9,
      actionObject: "NotificationsReport",
      actionName: "view",
      location: "/notify-log",
      icon: "sms"
    }
  ];

  const permitedLocations: any = [];
  permissions?.forEach((permission: any) => {
    const matchedByResource: any = find(
      propEq("actionObject", permission.actionObject)
    )(pagePermissions);
    if (!valueExists(matchedByResource)) return;

    const matched = find(propEq("actionName", permission.actionName))([
      matchedByResource
    ]);

    matched && permitedLocations.push(matched);
  });

  return permitedLocations.sort((a: any, b: any) => a.id - b.id);
}

export function isAccessDenied(permissions: any, pagePermission: any) {
  return !includes(pagePermission, permissions);
}

export function setLocale(locale: string) {
  setCookie("locale", locale, { path: "/" });
}

export function addMonthsToDate(date: Date, monthsNumber: number) {
  return addMonths(date, monthsNumber);
}
