import React, { createContext, useReducer } from "react";

type InitialStateType = {
  message?: string;
  variant?: string;
  showSnackbar: boolean;
};

type ActionType = "HIDE_SNACKBAR" | "SET_MESSAGE";

const initialState: InitialStateType = {
  message: undefined,
  variant: undefined,
  showSnackbar: false
};

const reducer = (
  state: InitialStateType,
  action: { type: ActionType; payload: Partial<InitialStateType> }
): InitialStateType => {
  const { type, payload } = action;
  switch (type) {
    case "HIDE_SNACKBAR":
      return { ...state, showSnackbar: false };
    case "SET_MESSAGE":
      return {
        ...state,
        showSnackbar: true,
        variant: payload.variant,
        message: payload.message
      };
    default:
      return state;
  }
};

const SnackbarContext = createContext<{
  state: InitialStateType;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null
});

const AppProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <SnackbarContext.Provider value={{ state, dispatch }}>
      {children}
    </SnackbarContext.Provider>
  );
};

export { SnackbarContext, AppProvider };
