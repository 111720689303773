import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { CssBaseline } from "@material-ui/core";
import Loader from "./components/molecules/Loader";
import "./util/global.css";
import { AppProvider } from "./components/_functional/snackbarContext";

const Home = lazy(() => import("./pages/Home"));
const Login = lazy(() => import("./pages/Login"));
const Collections = lazy(() => import("./pages/Collections"));
const MarketplaceCollections = lazy(
  () => import("./pages/MarketplaceCollections")
);
const CreateCollection = lazy(() => import("./pages/CreateCollection"));
const CreateMarketplaceCollection = lazy(
  () => import("./pages/CreateMarketplaceCollection")
);
const ManageCollections = lazy(() => import("./pages/ManageCollections"));
const Parents = lazy(() => import("./pages/Parents"));
const InviteParent = lazy(() => import("./pages/InviteParent"));
const Children = lazy(() => import("./pages/Children"));
const InviteChild = lazy(() => import("./pages/InviteChild"));
const InviteMunicipalityAdmin = lazy(
  () => import("./pages/InviteMunicipalityAdmin")
);
const MunicipalityAdmins = lazy(() => import("./pages/MunicipalityAdmins"));
const InviteServiceProviders = lazy(
  () => import("./pages/InviteServiceProviders")
);
const ServiceProviders = lazy(() => import("./pages/ServiceProviders"));
const MunicipalityManagers = lazy(() => import("./pages/MunicipalityManagers"));
const InviteMunicipalityManager = lazy(
  () => import("./pages/InviteMunicipalityManager")
);
const Register = lazy(() => import("./pages/Register"));
const AssignChildren = lazy(() => import("./pages/AssignChildren"));
const UnassignChildren = lazy(() => import("./pages/UnassignChildren"));
const AssignParents = lazy(() => import("./pages/AssignParents"));
const UnassignParents = lazy(() => import("./pages/UnassignParents"));
const Notify = lazy(() => import("./pages/Notify"));
const NotifyLog = lazy(() => import("./pages/NotifyLog"));

const App = () => {
  return (
    <Suspense fallback={<Loader />}>
      <CssBaseline />
      <Switch>
        <AppProvider>
          <Route exact path="/" component={Home} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/collections" component={Collections} />
          <Route
            exact
            path="/marketplace-collections"
            component={MarketplaceCollections}
          />
          <Route exact path="/parents" component={Parents} />
          <Route
            exact
            path="/municipality-admins/invite"
            component={InviteMunicipalityAdmin}
          />
          <Route
            exact
            path="/service-providers/invite"
            component={InviteServiceProviders}
          />
          <Route
            exact
            path="/municipality-admins"
            component={MunicipalityAdmins}
          />
          <Route exact path="/service-providers" component={ServiceProviders} />
          <Route
            exact
            path="/municipality-managers"
            component={MunicipalityManagers}
          />
          <Route
            exact
            path="/municipality-managers/:mmId/assign-children"
            component={AssignChildren}
          />
          <Route
            exact
            path="/municipality-managers/:mmId/assign-parents"
            component={AssignParents}
          />
          <Route
            exact
            path="/municipality-managers/:mmId/unassign-children"
            component={UnassignChildren}
          />
          <Route
            exact
            path="/municipality-managers/:mmId/unassign-parents"
            component={UnassignParents}
          />
          <Route
            exact
            path="/municipality-managers/invite"
            component={InviteMunicipalityManager}
          />
          <Route exact path="/parents/invite" component={InviteParent} />
          <Route exact path="/children" component={Children} />
          <Route exact path="/children/invite" component={InviteChild} />
          <Route exact path="/notify" component={Notify} />
          <Route exact path="/notify-log" component={NotifyLog} />
          <Route
            exact
            path="/collections/:ticketId/manage"
            component={ManageCollections}
          />
          <Route
            exact
            path="/collections/create"
            component={CreateCollection}
          />
          <Route
            exact
            path="/marketplace-collections/create"
            component={CreateMarketplaceCollection}
          />
          <Route exact path="/register/:token" component={Register} />
          <Route exact path="/r/:token" component={Register} />
        </AppProvider>
      </Switch>
    </Suspense>
  );
};

export default App;
